
<template>
  <v-container fluid>
    <v-row row wrap>
      <v-col cols="12">
        <h4 class="display-1 left break-name">{{ engagementPoll.name }}</h4>
        <br/>
        <v-chip
          disabled
          class="white--text break-name"
          :class="{ right: $vuetify.breakpoint.lg }"
          color="primary"
          v-if="engagementPoll.displayName"
        >{{ engagementPoll.displayName }}</v-chip>
      </v-col>

      <v-col cols="12">
        <v-card>
          <!-- General -->
          <v-card-text v-if="!displayCuts">
            <h5 class="headline mb-2">{{ $t('engagementReport.general_title') }}</h5>

            <p>{{ $t('engagementReport.general_desc') }}</p>

            <span v-if="displayReport">
              <x-report
                :demographics-selected="cutsSelected.totalPopulation"
                :list-of-demographics="{}"
                :poll-id="Number.parseInt($route.params.id)"
                :disable-button="disableButton"
                :button-text="$t('engagementReport.general_button')"
                :already-generated="computedAlreadyGeneratedOrg"
                report-type="general"
              ></x-report>
            </span>
          </v-card-text>

          <v-divider v-if="!displayCuts" class="mt-1 mb-3"></v-divider>

          <v-card-text v-if="!displayCuts">
            <span v-if="displayReport">
              <x-open-pie
                :disable-button="disableOpenPie"
                :open-answers="openAnswers"
                :engagement-name="engagementPoll.displayName || engagementPoll.name"
                :questions="engagementPoll.openQuestion"
              ></x-open-pie>
            </span>
          </v-card-text>

          <v-divider v-if="!displayCuts" class="my-3"></v-divider>

          <!-- Reporte resumen por cortes demográficos -->
          <v-card-text v-if="!displayCuts">
            <h5 class="headline mb-2">{{ $t('demographicReport.demographic_population_title') }}</h5>
            <p>{{ $t('demographicReport.demographic_population_desc') }}</p>
            <x-demographic-population
              :poll-id="Number.parseInt($route.params.id)"
              :already-generated="computedAlreadyGeneratedSummary"
              :disable-button="disableButton"
              :button-text="$t('demographicReport.demographic_population_button')"
            ></x-demographic-population>
          </v-card-text>

          <v-divider v-if="!displayCuts" class="my-3"></v-divider>

          <!-- Cortes demográficos -->
          <v-card-text>
            <h5 class="headline mb-2">{{ $t('engagementReport.demographic_title') }}</h5>
            <p>{{ $t('engagementReport.demographic_desc') }}</p>
            <v-switch
              v-model="displayCuts"
              :label="$t('engagementReport.display_demographic')"
            ></v-switch>

            <span v-show="displayCuts">
              <x-filter-demographic-items
                v-if="!loadingThreads"
                :cuts-selected="cutsSelected"
                :poll-id="Number.parseInt($route.params.id)"
                @receivers-modified="handleReceiversModified($event)"
                @demographics-filtered="setDemographicsFiltered($event)"
                @answers-fetched="handleReportButton($event)"
                @demographics-selects="demographicsSelects = $event"
                @loading="loadingFilters = $event"
              ></x-filter-demographic-items>

              <span v-if="displayReport">
                <x-report
                  :demographics-selected="cutsSelected.demographics"
                  :demographics-selects="demographicsSelects"
                  :list-of-demographics="cutsSelected.listOfDemographics || {}"
                  :poll-id="Number.parseInt($route.params.id)"
                  :disable-button="disableBottomButton"
                  :button-text="$t('engagementReport.items_button')"
                  :filters="demographicsFiltered"
                  :check-generated="checkIfAlreadyGenerated"
                  report-type="by_demographic"
                  @new-report="newPopulationReport"
                ></x-report>
              </span>
            </span>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text v-if="reportThreads.length" class="pa-0">
            <v-row class="mt-1 pr-8">
              <v-col cols="12" sm="8" class="pl-7">
                <h5 class="headline">{{ $t('engagementReport.generated_reports') }}</h5>
              </v-col>
              <!-- Refresh Page Button -->
              <v-col cols="12" sm="4" class="pr-8 text-right">
                <template v-if="showUpdateProgressBtn">
                  <span
                    class="caption d-inline-block mt-2 grey--text text--darken-1 cursor-pointer"
                    @click="refreshPage"
                  >
                    {{ $t('demographicReport.input_update_progress') }}
                  </span>
                  <v-btn fab dark x-small
                    absolute
                    right
                    color="primary"
                    class="refresh-fab mr-n1"
                    :loading="loadingRefresh"
                    @click="refreshPage"
                  >
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
              </v-col>
              <v-col cols="12" class="pt-0 pl-8">
                <p class="mb-0 text-justify" v-html="$t('engagementReport.generated_reports_desc')"></p>
              </v-col>
            </v-row>
            <!-- Report Requests -->
            <v-row>
              <v-col cols="12" class="my-0 pt-4 py-0">
                <x-report-threads
                  :threads="reportThreads"
                  :demographics="demographicsSelects"
                  :poll="engagementPoll"
                ></x-report-threads>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-row row wrap class="mt-5">
              <v-btn
                class="ml-5 mb-5"
                to="/engagement"
                large
              >{{ $t('input.back') }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="674px">
      <v-card>
        <v-spacer></v-spacer>
        <v-card-text class="pt-7 px-3">
          <h6>{{ $t('engagementReport.no_answers_modal_msg') }}</h6>
        </v-card-text>
        <v-card-actions class="px-2">
          <v-btn large block
            color="primary"
            class="white--text"
            @click="closeAndGoBack"
          >
            {{ $t('input.accept') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <x-loading v-if="loading || loadingFilters" :display="loading || loadingFilters"/>
  </v-container>
</template>

<script>

import engagementsService from '../../services/engagements';
import resolver from '../../utils/resolver';
import XReport from './report.vue';
import XOpenPie from './open-pie.vue';
import XFilterDemographicItems from './components/filter-demographic-items.vue';
import XDemographicPopulation from './demograpich-population-report.vue'
import XReportThreads from './report-threads.vue'

export default {
  components: {
    XReport,
    XOpenPie,
    XFilterDemographicItems,
    XDemographicPopulation,
    XReportThreads
  },
  data() {
    return {
      cutsSelected: {},
      displayCuts: false,
      disableButton: false,
      disableBottomButton: false,
      engagementPoll: {},
      displayReport: true,
      openAnswers: [],
      disableOpenPie: true,
      demographicsFiltered: [],
      demographicsSelects: {},
      showModal: false,
      reportThreads: [],
      loading: false,
      loadingFilters: false,
      loadingThreads: true,
      loadingRefresh: false,
      limit: 15000,
      totalOpenAnswers: 0
    };
  },
  created() {
    this.loading = true;
    this.loadingThreads = true;
    const limitOpt = {
      limit: this.limit,
      offset: this.openAnswers.length
    }
    resolver
      .all({
        engagement: engagementsService.getOneById(this.$route.params.id),
        answers: engagementsService.getOpenAnswers(this.$route.params.id, {getCount: true, limitOpt}),
        threads: engagementsService.reportStatus(this.$route.params.id, 'Engagement')
      })
      .then(({ engagement, answers, threads }) => {
        if (engagement.item.status !== 'completed') { this.$router.go(-1); }
        this.engagementPoll = engagement.item;
        this.openAnswers = answers.answers;
        this.reportThreads = threads;
        this.loadingThreads = false;

        this.totalOpenAnswers = answers.totalOpenAnswers;
        if (this.openAnswers.length < this.totalOpenAnswers) {
          this.getNextAnswers();
        } else {
          this.disableOpenPie = !this.openAnswers.length;
          this.loading = false;
        }
      })
      .catch((err) => {
        this.$store.dispatch('alert/error', this.$t(err.code));
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    computedAlreadyGeneratedOrg () {
      if (this.reportThreads.length) {
        const found = this.reportThreads.find((t) => t.reportType === 'general')
        if (found) return true
      }
      return false
    },
    computedAlreadyGeneratedSummary () {
      if (this.reportThreads.length) {
        const found = this.reportThreads.find((t) => t.reportType === 'summary')
        if (found) return true
      }
      return false
    },
    showUpdateProgressBtn () {
      let shouldShow = false
      if (this.reportThreads.length) {
        const inProgress = this.reportThreads.find((t) => {
          return t.progressPercentage !== 100
        })
        if (inProgress) {
          shouldShow = true
        }
      }
      return shouldShow
    }
  },
  methods: {
    refreshPage() {
      this.loadingRefresh = true
      window.location.reload()
    },
    closeAndGoBack () {
      this.showModal = !this.showModal
      this.$router.go(-1)
    },
    newPopulationReport() {
      const index = this.reportThreads.findIndex(t => t.reportType === 'by_demographic');
      this.reportThreads.splice(index, 1);
    },
    getNextAnswers() {
      const limitOpt = {
        limit: this.limit,
        offset: this.openAnswers.length
      }
      engagementsService.getOpenAnswers(this.$route.params.id, {limitOpt})
        .then(res => {
          this.openAnswers.push(...res.answers);

          if (this.openAnswers.length < this.totalOpenAnswers) {
            this.getNextAnswers();
          } else {
            this.disableOpenPie = !this.openAnswers.length;
            this.loading = false;
          }
        });
    },
    checkIfAlreadyGenerated (selectedFilters) {
      const itemsReports = this.reportThreads
        .filter(x => x.reportType === 'by_demographic')
        .map(x => JSON.parse(x.criteria).filters)

      if (itemsReports.length) {
        let matchCount = 0
        itemsReports.forEach(r => {
          let cnt = 0
          Object.keys(selectedFilters).forEach(key => {
            if (r[key]) {
              switch (key) {
                case 'age':
                case 'antiquity':
                  if (selectedFilters[key].every((val, i) => val === r[key][i])) {
                    cnt++
                  }
                  break
                case 'genders':
                  if (r[key] === selectedFilters[key]) {
                    cnt++
                  }
                  break
                default:
                  const arr1 = selectedFilters[key]
                  const arr2 = r[key]
                  if (arr1.length === arr2.length && arr1.every(v => arr2.includes(v))) {
                    cnt++
                  }
                  break
              }
            }
          })
          if (Object.keys(selectedFilters).length === cnt && cnt === Object.keys(r).length) {
            matchCount++
          }
        })

        return matchCount > 0
      }
      return false
    },
    handleReceiversModified(e) {
      this.disableBottomButton = e;
    },
    reloadReport() {
      this.displayReport = false;
      this.$nextTick().then(() => this.displayReport = true);
    },
    setDemographicsFiltered(e) {
      this.demographicsFiltered = e;
    },
    handleReportButton(e) {
      this.disableButton = !e;
      this.showModal = e;
    }
  },
};
</script>

<style>
  .refresh-fab.v-btn--fab.v-btn--absolute {
    z-index: 3;
  }
  .showRepBtn {
    padding: 10px 32px !important;
    min-height: 44px !important;
    height: unset !important;
  }
  .showRepBtn div.v-btn__content {
    flex: 1 1 auto;
    white-space: unset;
  }
  .break-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
